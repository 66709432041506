

window.addEventListener('load', () => {
	accordeonActionsAbsolutePosition('.accordeon-abs', '.accordeon-opener');
	accordeonActionsAbsolutePosition('.btn-classic-accordeon', '.picto-opener')

	inputFileChange();
	closeNotifications();
	resetFilters();
});


// ==================================================
// FUNCTION TO OPEN AND CLOSE ACCORDEON 
// ==================================================

function accordeonActionsAbsolutePosition(container, opener){
	return document.querySelectorAll(container).forEach(accordeon => {
		accordeon.querySelector(opener).addEventListener("mouseup", (e) => {
			e.target.parentElement.classList.toggle('active');
		});
	});
}

function inputFileChange(){
    document.querySelectorAll('.input-file-container .files-custom').forEach(fileInput => {
        const docs = [];
        fileInput.addEventListener('change', e => {
            if(e.target.files.length >= 1){
                e.target.parentElement.querySelectorAll('.imported-file').length !== 0 && e.target.parentElement.querySelectorAll('.imported-file').forEach(file => file.remove());
                for (let i = 0, numFiles = e.target.files.length; i < numFiles; i++) {
                    const file = e.target.files[i];
                    e.target.parentElement.querySelector('.docsAttach .table-header').insertAdjacentHTML('afterend', '<tr data-attachment-id-notif='+i+' class="imported-file"><td>'+file.name+'</td></tr>')
                }
                e.target.parentElement.classList.add('active');
            }
        })
    });
    document.querySelectorAll('.input-file-container label').forEach(item => {
        const plusPictoSrc = item.querySelector('.picto-great').attributes.src.value;
        const pictoImport = item.querySelector('.picto-great');
        item.addEventListener('drop', (e) => {
            e.preventDefault();
            const inputFile = e.target.parentElement.querySelector('input.files-custom');
            inputFile.files = e.dataTransfer.files;
            if(inputFile.files.length >= 1){
                inputFile.parentElement.querySelectorAll('.imported-file').length !== 0 && inputFile.parentElement.querySelectorAll('.imported-file').forEach(file => file.remove());
                for (let i = 0, numFiles = inputFile.files.length; i < numFiles; i++) {
                    const file = inputFile.files[i];
                    inputFile.parentElement.querySelector('.docsAttach .table-header').insertAdjacentHTML('afterend', '<tr data-attachment-id-notif='+i+' class="imported-file"><td>'+file.name+'</td></tr>')
                }
                inputFile.parentElement.classList.add('active');
                item.style.background = "#1aa0e0";
                pictoImport.attributes.src.value = plusPictoSrc;
                item.querySelector('p').innerText = "Modifiez ou ajoutez des documents";
            }
        });

        item.addEventListener('dragover', (e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = "move";
            item.style.background = "#1aa0e0";
            item.querySelector('p').style.color = "#ffffff";
            item.querySelector('p').style.fontWeight = "800";
            pictoImport.attributes.src.value = "/assets/pictos/import.svg";
        });
        item.addEventListener('dragleave', (e) => {
            e.preventDefault();
            item.style.background = "#e4f3fe";
            item.querySelector('p').style.color = "#000000";
            item.querySelector('p').style.fontWeight = "500";
            pictoImport.attributes.src.value = plusPictoSrc;
        });
    })
}

function closeNotifications(){
	document.querySelectorAll('.deletable-item').forEach(item => {
		item.querySelector(".picto-close").addEventListener("click", e => {
            e.preventDefault();
            console.log(e.target);

			// ID FOR FUTURE USAGE TO DELETE NOTIFICATION
			const deleteUrl = e.target.href;

			if (deleteUrl) {
                fetch(deleteUrl);
            }
            item.remove();

			return false;
        })
	});
}

function resetFilters(){
	document.querySelectorAll('.filter-search').forEach(formFilter => {
		formFilter.querySelector('.reset-field').addEventListener('click', () => {
			formFilter.reset();
		})
	})
}

// ==================================================
// FUNCTION TO FADE OUT FLASH MESSAGE
// ==================================================

window.onload = function() {
    window.setTimeout(fadeout, 5000);
}

function fadeout() {
	if (document.getElementById('flash-message')) {
        document.getElementById('flash-message').style.opacity = '0';
    }
}

// ====================================================================================================
// FUNCTION TO TOGGLE 'REQUIRED' STATEMENT FROM SELECT MANUFACTURER INPUT TO IDENTITY & CONTACT INPUT
// (Only used when current user is a subsidiary in template certificate_request/request_form.html.twig)
// ====================================================================================================

function stateToggler() {
	// Remove 'required' statement form select manufacturer input
    // when it is displayed to 'none' (= when using the form to create a new manufacturer) in order to enable submitting the form
	if (document.querySelector('#select-manufacturer') !== null) {
        var selectManufacturer = document.querySelector('#select-manufacturer');
        selectManufacturer.classList.contains("d-none") ? selectManufacturer.querySelector('select').removeAttribute('required') : selectManufacturer.querySelector('select').setAttribute('required', 'required');
    }

    // Remove 'required' statement from manufacturer identity & contact input
    // when it is displayed to 'none' (= when choosing to select a existing manufacturer) in order to enable submitting the form
    if (document.querySelector('#manufacturer-identity') !== null && document.querySelector('#manufacturer-contact') !== null) {
        var identityToDisable = document.querySelector('#manufacturer-identity');
        var contactToDisable = document.querySelector('#manufacturer-contact');

        if (identityToDisable.classList.contains('d-none')) {
            identityToDisable.querySelectorAll('input').forEach(function (item) {
                if (item.required === true) {
                    return item.removeAttribute('required')
                }
            });
            contactToDisable.querySelectorAll('input').forEach(function (item) {
                return item.removeAttribute('required')
            });
        } else {
            identityToDisable.querySelectorAll('input').forEach(function (item) {
                if (item.required !== false) {
                    item.setAttribute('required', 'required')
                }
            });
            contactToDisable.querySelectorAll('input').forEach(function (item) {
                return item.setAttribute('required', 'required')
            });
        }
    }
}

stateToggler();

if (document.querySelector('#btn-create-manufacture') !== null) {
    document.getElementById("btn-create-manufacture").addEventListener('click', function (e) {
        var button = e.target;

        // Toggle 'display none' manufacturer selection OR form to create manufacturer
        // by clicking on the dedicated button
        document.querySelector('#select-manufacturer').classList.toggle("d-none");
        document.querySelector('#manufacturer-identity').classList.toggle("d-none");
        document.querySelector('#manufacturer-contact').classList.toggle("d-none");
        if (document.querySelector('#select-manufacturer.d-none')) {
            document.querySelector('#manufacturer-referent-choice').classList.add("d-none");
            document.querySelector('#manufacturer-referent-no-choice').classList.add("d-none");
        }

        button.textContent === button.dataset.labelSelect ? (button.textContent = button.dataset.labelCreate) : (button.textContent = button.dataset.labelSelect);

        stateToggler();

        // Put manufacturer selection input to "empty" when clicking on the button 'create a new manufacturer'
        document.querySelector('#certificate_request_form_manufacturerSelection').children[0].selected = true;
    });
}