require('babel-polyfill');
require('../css/app.scss');
require('./actions.js');

// ==================================================
// FUNCTION TO GET SCREEN WIDTH x HEIGHT
// ==================================================
window.addEventListener('load', ()=> {
    (function widthTester(){
        return document.getElementById('width-tester').innerHTML = `${window.innerWidth} x ${window.innerHeight}`;
    })()
});

